import React, { FC } from 'react';

interface IPreview {
  pageContext: {
    header: string;
    text: string;
    background: string;
  }
}

const Preview: FC<IPreview> = ({
  pageContext: {
    header,
    text,
    background,
  },
}) => (
  <div
    style={{
      width: 1200,
      height: 675,
      padding: 40,
      paddingBottom: 0,
      boxSizing: 'border-box',
      overflowX: 'hidden',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: 'white',
      fontFamily: 'Arial, Helvetica, sans-serif',
    }}
  >
    <h1
      style={{
        color: '#c0df16',
        fontSize: 64,
        maxWidth: '100%',
        paddingRight: '1em',
      }}
    >
      {header}
    </h1>
    <h2
      style={{
        color: '#fff',
        fontSize: 64,
        maxWidth: '100%',
        paddingRight: '1em',
      }}
    >
      {text}
    </h2>
  </div>
);

export default Preview;
